import React from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import { useEffect } from "react"

const PrivacyPolicy = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <Header />
            <div className='privacy'>

                <h1>Privacy Policy</h1>
                <h4><b><i>Last updated: June 26, 2023</i></b></h4>
                <p>At Dwarka School of Music, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your personal data when you visit our website or use our services. By accessing or using our website, you consent to the practices described in this policy.</p>

                <div>
                    <h2>Information We Collect</h2>
                    <h3>1.1 Personal Information:</h3>
                    <p>We may collect personal information from you when you voluntarily provide it to us, such as when you fill out contact forms, register for classes, or subscribe to our newsletter. This may include your name, email address, phone number, and any other information you choose to provide.</p>
                    <h3>1.2 Usage Data:</h3>
                    <p>We may automatically collect certain information about your visit to our website, including your IP address, browser type, device information, pages visited, and the duration of your visit. This information helps us analyze trends, administer the site, and improve our services.</p>
                </div>


                <div>
                    <h2>Use of Information</h2>
                    <h3>2.1 Personal Information:</h3>
                    <p>We use the personal information you provide to fulfil your requests, respond to inquiries, and provide you with relevant information about our services. We may also use your information to send you newsletters or promotional materials if you have opted to receive them.</p>
                    <h3>2.2 Usage Data:</h3>
                    <p>We may use usage data to analyze and improve our website, customize the user experience, and to track the effectiveness of our marketing campaigns.</p>
                </div>

                <div>
                    <h2>Disclosure of Information:</h2>
                    <h3>3.1 Service Providers:</h3>
                    <p>We may engage trusted third-party service providers to assist us in operating our website or conducting our business. These providers may have access to your personal information but are obligated to keep it confidential and use it solely for the purposes for which we engage them.</p>
                    <h3>3.2 Legal Requirements:</h3>
                    <p>We may disclose your personal information if required to do so by law or if we believe that such action is necessary to comply with a legal obligation, protect our rights or property, or investigate, prevent, or take action regarding illegal activities, suspected fraud, or situations involving potential threats to the safety of any person.</p>
                </div>

                <div>
                    <h2>Security:</h2>
                    <p>We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                </div>

                <div>
                    <h2>Third-Party Links:</h2>
                    <p>Our website may contain links to third-party websites or services that are not owned or controlled by us. This Privacy Policy applies only to our website, and we have no control over the privacy practices of these third-party websites. We encourage you to review their privacy policies before providing any personal information.</p>

                </div>

                <div>
                    <h2>Children's Privacy:</h2>
                    <p>Our website and services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children. If you believe that we have inadvertently collected information from a child, please contact us immediately, and we will take steps to remove that information from our systems.</p>

                </div>

                <div>
                    <h2>Changes to the Privacy Policy:</h2>
                    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date at the top of the policy will be revised. We encourage you to review this policy periodically for any updates.</p>

                </div>

                <div>
                    <h2>Contact Us:</h2>
                    <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at:</p>
                    <p>Dwarka School of Music</p>
                    <p>Address: E-1083, 1st Floor, MPS Plaza, Ramphal Chowk Rd, Sector 7, Dwarka, New Delhi, Delhi 110075</p>
                    <p> Email:<a href="mailto:contact.dsom@gmail.com" style={{ textTransform: "lowercase" }}>contact.dsom@gmail.com
                    </a></p>
                    <p> Phone: <a href="tel:+918287202085"> +918287202085</a></p>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default PrivacyPolicy
