import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../src/components/Pages/Home"
import About from "../src/components/Pages/About"
import OurTeam from "../src/components/Pages/Our_Team"
import Gallery from "../src/components/Pages/Gallery"
import Event from "./components/Pages/Event/Event"
import "../src/components/Css/main.css"
import "bootstrap-icons/font/bootstrap-icons.css";
import "../src/components/Css/media.css"
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import Keyboard from './components/Pages/Classes/Keyboard';
import Dance from './components/Pages/Classes/Dance';
import Vocal from './components/Pages/Classes/Vocal';
import Guitar from './components/Pages/Classes/Guitar';
import Drums from './components/Pages/Classes/Drums';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/our_team" element={<OurTeam />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/event" element={<Event />} />

          {/* classes */}
          {/* <Route path="/drums" element={<Drums />}/>
          <Route path="/guitar" element={<Guitar />}/>
          <Route path="/vocal" element={<Vocal />}/>
          <Route path="/keyboard" element={<Keyboard />}/>
          <Route path="/dance" element={<Dance />}/> */}

        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
