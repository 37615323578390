import React from 'react'
import whatsapp_icon from '../Images/Icons/icons8-whatsapp-100.png'

const Whatsapp = () => {
    return (
        <div>
            <div className="whatsapp">
                <a href="https://wa.me/918287202085"><img src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691583942/Dwarka%20School%20Of%20Music/Icons/whatsapp_185988_fwg1ec.png" alt="whatsapp" /></a>
            </div>
        </div>
    )
}

export default Whatsapp
