import React from 'react'
import Header from '../../Utilities/Header'
import Footer from '../../Utilities/Footer'
import "./Event.scss"
// import firebase from '../../Database/firebase';


const Event = () => {
    return (
        <div>
            <Header />
            <div className="event">
                <div className="banner">
                    <h3>enjoy your music</h3>
                </div>
            </div>
            <Footer />

        </div>
    )
}

export default Event