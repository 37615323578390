import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import drums from "../Images/Classes/drums1.png"
import guitar from "../Images/Classes/guitar1.png"
import keyboard from "../Images/Classes/keyboard1.png"
import vocal from "../Images/Classes/vocal1.png"
import dance from "../Images/Classes/dance1.png"
import about from "../Images/About/abt.jpg"
import gallery1 from '../Images/Gallery/Infrastructure/1.webp'
import gallery2 from '../Images/Gallery/Students/6.jpg'
import gallery3 from '../Images/Gallery/Infrastructure/3.webp'
import gallery5 from '../Images/Gallery/Infrastructure/6.webp'
import himanshu from "../Images/Team/himanshu.webp"
import siddhant from "../Images/Team/sidhant.webp"
import manoj from "../Images/Team/manoj.webp"
import dipasree from "../Images/Team/Dipashree Chaterjee.png"
import testimonial_1 from "../Images/Testimonials/aditya.webp"
import testimonial_2 from "../Images/Testimonials/ravi.webp"
import testimonial_3 from "../Images/Testimonials/pooja.webp"
import testimonial_4 from "../Images/Testimonials/devansh.webp"
import prayagraj from '../Images/Affiliations/prayagraj.webp'
import rsl from '../Images/Affiliations/rsl.webp'
import pracheen from '../Images/Affiliations/pracheen_kala_kendra.webp'
import trinity from '../Images/Affiliations/trinity_college_london_logo.webp'
import achievement1 from '../Images/Achievements/1.webp'
import achievement2 from '../Images/Achievements/2.webp'
import achievement3 from '../Images/Achievements/3.webp'
import logo from "../Images/Logo/header-logo.png"
import { Link } from 'react-router-dom'
import slider1 from "../Images/Slider/slider1.webp"
import slider2 from "../Images/Slider/slider2.webp"
import slider3 from "../Images/Slider/slider3.webp"
import { useRef } from 'react'
import { useState, useEffect } from "react"
import emailjs from '@emailjs/browser';
import Whatsapp from '../Utilities/Whatsapp'
import google from "../Images/Icons/google_2991148.png"
import facebook from "../Images/Icons/facebook_145802.png"
import instagram from "../Images/Icons/instagram_2111463.png"
import call from "../Images/Icons/telephone_7795341.png"
import mail from "../Images/Icons/mail_7286262.png"
import location from "../Images/Icons/placeholder_727606.png"
import { ElfsightWidget } from 'react-elfsight-widget';
import youtube from "../Images/Icons/youtube.png"
import popupimg from "../Images/dance & music education as after school program.png"

const Home = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    setshowpopup("block");

  }, []);

  function Component() {
    return <ElfsightWidget widgetId='70be514c-fae6-484c-9254-1b945d894a06' />
  }


  const [name, setName] = useState('') // useState to store First Name
  const [email, setEmail] = useState('') // useState to store Email address of the user
  const [mobile, setMobile] = useState('') // useState to store Mobile Number
  const [post, setPost] = useState('') // useState to store subject of the user
  const [message, setMessage] = useState('') // useState to store subject of the user
  const form = useRef();

  function validateForm() {


    if (name.length === 0) {
      alert('Invalid Form, Name can not be empty');
      return
    }
    if (email.length === 0) {
      alert('Invalid Form, Email Address can not be empty');
      return
    }

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address');
      return
    }

    if (message.length === 0) {
      alert('Invalid Form, Message can not be empty');
      return
    }

    if (typeof mobile !== "undefined") {

      var pattern = new RegExp(/^[0-9\b]+$/);

      if (!pattern.test(mobile)) {
        alert("Please enter only number.");
        return
      } else if (mobile.length !== 10) {
        alert("Please enter valid phone number.");
        return
      }

    }

  }

  const sendEmail = (e) => {
    e.preventDefault();
    // service_id, templte_id and public key will get from Emailjs website when you create account and add template service and email service 
    emailjs.sendForm('service_39yn67f', 'template_nc1vq04', e.target,
      '9MnBiE7Mx9H96KTc9')
      .then((result) => {
        console.log(result)
        alert("Sent Successfully");
      }, (error) => {
        console.log(error)
        alert("oops! some error occured");
      });
  };

  const [showpopup, setshowpopup] = useState("none")



  return (
    <div>
      <div className='popup' style={{ display: showpopup }}>
        <svg onClick={() => setshowpopup("none")} xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 24 24" width="512" height="512"><path d="M14.121,12,18,8.117A1.5,1.5,0,0,0,15.883,6L12,9.879,8.11,5.988A1.5,1.5,0,1,0,5.988,8.11L9.879,12,6,15.882A1.5,1.5,0,1,0,8.118,18L12,14.121,15.878,18A1.5,1.5,0,0,0,18,15.878Z" /></svg>

        <img src={popupimg} alt="popup" />
      </div>
      {/* <Slider/> */}
      {/* Navbar */}
      <div id='header'>

        <Link to={'/'} >
          <div className="header-logo">
            <img src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691563397/Dwarka%20School%20Of%20Music/Logo/m_logo_jispoe.ico" alt="logo" />
          </div>
        </Link>
        <header className='header-navbar'>
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <a className="navbar-brand" ></a>
              <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link" aria-current="page" href="/">Home</a>
                  </li>
                  <li className="nav-item">
                    <Link to={'/about'} className="nav-link">about us</Link>
                  </li>
                  {/* <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Classes
                    </a>
                    <ul class="dropdown-menu">
                      <li><Link class="dropdown-item" to={"/guitar"}>Guitar</Link></li>
                      <li><Link class="dropdown-item" to={"/drums"}>Drums</Link></li>
                      <li><Link class="dropdown-item" to={"/vocal"}>Vocal</Link></li>
                      <li><Link class="dropdown-item" to={"/keyboard"}>Keyboard</Link></li>
                      <li><Link class="dropdown-item" to={"/dance"}>Dance</Link></li>
                    </ul>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link" href="#classes">classes</a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"/our_team"}>our team</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"/gallery"}>gallery</Link>
                  </li>
                </ul>

              </div>
            </div>
          </nav>
        </header>


      </div>

      {/* Navbar Ends */}

      {/* Slider */}
      <div className="header-slider">
        <div class="carousel-wrapper">
          <Carousel infiniteLoop useKeyboardArrows autoPlay>
            <div>
              <img src={slider1} />
              <div class="header-carousel-caption">
                <h5>Discover the rhythm of <br />your passion with <br />  <span>our Music Academy </span></h5>
                <a href='#classes'>Start learning</a>
              </div>
            </div>
            <div>
              <img src={slider2} />
              <div class="header-carousel-caption">
                <h5>Unleash Your Musical<br /> Potential with <br /><span>our Academy's Expert Guidance </span> </h5>
                <a href='#classes'>Start learning</a>
              </div>
            </div>
            <div>
              <img src={slider3} />
              <div class="header-carousel-caption">
                <h5>Discover Your Inner Musician: <br /> Unleash Your Potential <br /><span>with Our Academy</span>  </h5>
                <a href='#classes'>Start learning</a>
              </div>
            </div>
          </Carousel>
        </div>
        <div className="header-form">
          <h5>get in touch</h5>
          <form ref={form} onSubmit={sendEmail}>
            <div>
              <input type="text" name="name"
                placeholder="Name"
                autoFocus
                value={name} onChange={(e) => setName(e.target.value)}
                required className='header-form-div' />

              <input type="text" name="mobile"
                placeholder="Mobile"
                autoFocus
                value={mobile} onChange={(e) => setMobile(e.target.value)}
                required className='header-form-div' />

            </div>
            <div>

              <input type="email" name='email' placeholder="Email"
                autoFocus
                value={email} onChange={(e) => setEmail(e.target.value)}
                required className='header-form-div' />

              <select value={post} name='post' onChange={(e) => setPost(e.target.value)} className='header-form-div'>
                <option value="class">class</option>
                <option value="drums">drums</option>
                <option value="guitar">guitar</option>
                <option value="keybaord">keybaord</option>
                <option value="vocal">vocal</option>
                <option value="dance">dance</option>
              </select>
            </div>

            <div>
              <textarea name="message" cols="30" placeholder='Your Message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}></textarea>
            </div>
            <div>
              <button type='submit' onClick={validateForm}>register now</button>
            </div>
          </form>
        </div>
      </div>
      {/* Slider Ends */}

      {/* form */}

      <div className="form-toggle">
        <form ref={form} onSubmit={sendEmail}>
          <h5>get in touch</h5>

          <div>
            <input className='header-form-div' type="text"
              name="user_name"
              id="name"
              placeholder='Your Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
              required />

            <input className='header-form-div' type="text" name="user_phone"
              id="phone"
              placeholder='Phone Number'
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required />

          </div>
          <div>

            <input className='header-form-div' type="email"
              name="user_email"
              id="email"
              placeholder='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required />

            <select aria-label="Default select example" value={post} name='post' onChange={(e) => setPost(e.target.value)} className="header-form-div form-select">
              <option value="drums">drums</option>
              <option value="guitar">guitar</option>
              <option value="keybaord">keybaord</option>
              <option value="vocal">vocal</option>
              <option value="dance">dance</option>
            </select>
          </div>

          <div>
            <textarea id="message"
              cols="30"
              rows="10"
              placeholder='Your Message'
              name="user_message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required></textarea>
          </div>
          <div>
            <button type="submit"
              className="btn btn-light"
              value="Send"
              onClick={() => { validateForm() }}>register now</button>
          </div>
        </form>
      </div>

      {/* form ends */}

      {/* whatsapp */}
      <Whatsapp />
      {/* whatsapp ends */}

      {/* about section */}
      <section id='about'>
        <div className='about'>

          <div className="about-pic">
            <img src={about} alt="about" className='img-fluid' />
          </div>

          <div className="about-text">
            <h1>About us</h1>

            <h6>Established in 2015, At Dwarka School Of Music- we believe in the power of music to inspire, educate, and transform lives. We provide exceptional music education and fostering a lifelong love and appreciation for music in our students.</h6>
            <h6>Our Academy is home to a team of highly skilled and passionate music educators who are committed to nurturing each student's musical talents and abilities. Whether you're a beginner or an advanced musician, our comprehensive curriculum is designed to meet the unique needs and goals of every student.</h6>
            <h6>We offer a wide range of music lessons and classes for various instruments, including guitar, drums, Keyboard, Vocals and more. Our experienced instructors utilize innovative teaching methods, combining traditional techniques with modern approaches, to create a dynamic and engaging learning environment.</h6>
          </div>
        </div>
      </section>
      {/* about section ends */}

      {/* classes section */}
      <section id="classes">

        <h1>Classes</h1>

        <div className="classes">
          <div className="classes-body">
            <img src={drums} alt="" />
            <h4>Drums</h4>
            <div className="classes-text">
              <h3>"Unleash your rhythm, master the beat: Embrace the power of drums."</h3>
              <p>Drums are the heartbeat of music. From the thunderous bass drum to the sharp crack of the snare and the shimmering resonance of the cymbals, each drum component adds its unique voice to the percussive symphony.</p>
            </div>
          </div>

          <div className="classes-body">
            <img src={guitar} alt="" />
            <h4>guitar</h4>
            <div className="classes-text">
              <h3>"Strum your soul, ignite your passion: Let your guitar sing."</h3>
              <p >The guitar is a timeless instrument that has the power to captivate and inspire. With its six strings and versatile range, the guitar can create a symphony of melodies, chords, and emotions.  </p>
            </div>
          </div>

          <div className="classes-body">
            <img src={keyboard} alt="" />
            <h4>keyboard</h4>
            <div className="classes-text">
              <h3>"Unlock musical magic, create limitless melodies: Embrace the enchantment of the keyboard."</h3>
              <p >The musical keyboard is a gateway to boundless creativity and expression. With its arrangement of keys spanning octaves, it offers a vast spectrum of tones and melodies at our fingertips. </p>
            </div>
          </div>

          <div className="classes-body">
            <img src={vocal} alt="" />
            <h4>vocal</h4>
            <div className="classes-text">
              <h3>"Set your voice free, let your emotions soar: Discover the incredible power of vocals."</h3>
              <p >Vocal singing is the ultimate form of musical expression. With nothing but the power of the human voice, singers have the ability to convey a range of emotions, tell stories, and touch the hearts of listeners. </p>
            </div>
          </div>

          <div className="classes-body">
            <img src={dance} alt="" />
            <h4>dance</h4>
            <div className="classes-text">
              <h3>"Where Movement Ignites Magic"</h3>
              <p >Dance is the captivating language of movement, an artistic expression that communicates emotions, stories, and cultures through graceful gestures and rhythmic patterns. From classical ballet's elegance to contemporary dance's innovation, it's a universal form of communication that bridges words and cultures.</p>
            </div>
          </div>
        </div>

      </section >
      {/* classes section ends */}

      {/* affiliations */}
      <section id='affiliations'>

        <div className='affiliations'>
          <h1>affiliations</h1>

          <div className='cer-body'>
            <div className="cer-body-text">

            </div>
            <div className='cer-body-img'>

              <ul id="Frames">
                <li className="Frame"><img src={prayagraj} className='img-fluid' alt="msme" /></li>
              </ul>

              <ul id="Frames">
                <li className="Frame"><img src={rsl} className='img-fluid' alt="startup" /></li>
              </ul>

              <ul id="Frames">
                <li className="Frame"><img src={pracheen} className='img-fluid' alt="startup" /></li>
              </ul>

              <ul id="Frames">
                <li className="Frame"><img src={trinity} className='img-fluid' alt="startup" /></li>
              </ul>

            </div>
          </div>
        </div>
      </section>
      {/* affiliations ends */}

      {/* team starts */}
      <section id='our_team'>
        <div className="responsive-container-block outer-container">
          <div className="responsive-container-block inner-container">

            <h1>our faculty</h1>
            {/* <div className="responsive-container-block">

              <div className="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12 team-card-container">
                <div className="team-card">
                  <div className="img-wrapper">
                    <img className="team-img" src={himanshu} alt='himanshu' />
                  </div>
                  <p className="text-blk name">
                    Himanshu
                  </p>
                  <p className="text-blk position">
                    Guitar and Keys
                  </p>

                </div>
              </div>
              <div className="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12 team-card-container">
                <div className="team-card">
                  <div className="img-wrapper">
                    <img className="team-img" src={siddhant} alt='siddhant' />
                  </div>
                  <p className="text-blk name">
                    Siddhant
                  </p>
                  <p className="text-blk position">
                    Piano and Guitar
                  </p>

                </div>
              </div>
              <div className="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12 team-card-container">
                <div className="team-card">
                  <div className="img-wrapper">
                    <img className="team-img" src={manoj} alt='manoj' />
                  </div>
                  <p className="text-blk name">
                    Manoj
                  </p>
                  <p className="text-blk position">
                    Guitar and Drum
                  </p>

                </div>
              </div>
              <div className="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12 team-card-container">
                <div className="team-card">
                  <div className="img-wrapper">
                    <img className="team-img" src={dipasree} alt='manoj' />
                  </div>
                  <p className="text-blk name">
                    Dipasree
                  </p>
                  <p className="text-blk position">
                    Dance
                  </p>

                </div>
              </div>
            </div> */}

            <div class="teamGrid">
            <svg className='icon' id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m430.695 0h-349.39c-44.832 0-81.305 36.473-81.305 81.304v349.392c0 44.831 36.473 81.304 81.305 81.304h349.391c44.831 0 81.304-36.473 81.304-81.304v-349.392c0-44.831-36.473-81.304-81.305-81.304zm-25.88 267.313-69.596 69.596c-3.124 3.125-7.219 4.687-11.313 4.687s-8.189-1.562-11.313-4.687c-6.249-6.248-6.249-16.379 0-22.627l42.281-42.282h-236.372c-8.837 0-16-7.164-16-16s7.163-16 16-16h236.372l-42.281-42.281c-6.249-6.249-6.249-16.379 0-22.627 6.248-6.249 16.379-6.249 22.627 0l69.596 69.595c3.001 3.001 4.687 7.07 4.687 11.314s-1.687 8.312-4.688 11.312z"/></svg>
              <div class="colmun">
                <div class="teamcol">
                  <div class="teamcolinner">
                    <div class="avatar"><img src={manoj} alt="Member" /></div>
                    <div class="member-name"> <h2 align="center">Manoj Kumar
                    </h2> </div>
                    <div class="member-info"><p align="center">Guitar and Drum</p></div>

                  </div>
                </div>
              </div>
              <div class="colmun">
                <div class="teamcol">
                  <div class="teamcolinner">
                    <div class="avatar"><img src={himanshu} alt="Member" /></div>
                    <div class="member-name"> <h2 align="center">Himanshu Sharma
                    </h2> </div>
                    <div class="member-info"><p align="center">Guitar and Keys</p></div>

                  </div>
                </div>
              </div>
              <div class="colmun">
                <div class="teamcol">
                  <div class="teamcolinner">
                    <div class="avatar"><img src={dipasree} alt="Member" /></div>
                    <div class="member-name"> <h2 align="center">Dipasree Chaterjee
                    </h2> </div>
                    <div class="member-info"><p align="center">Dance</p></div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* team ends */}

      {/* testimonials */}
      <section id='testimonials'>
        <h1>testimonials</h1>
        <div className="after"></div>
        {/* <div className='testimonials'>

          <figure className="snip1157">
            <blockquote>Music Class is the best time of my Day.
              <div className="arrow"></div>
            </blockquote>
            <img src={testimonial_1} alt="sq-sample3" />
            <div className="author">
              <h5>Avidaan Joshi<br /><span>Student</span></h5>
            </div>
          </figure>
          <figure className="snip1157 hover">
            <blockquote>Music Class is the best time of my Day.
              <div className="arrow"></div>
            </blockquote>
            <img src={testimonial_2} alt="sq-sample27" />
            <div className="author">
              <h5>Jess Malik<br /><span>Student</span></h5>
            </div>
          </figure>
          <figure className="snip1157">
            <blockquote>Music Class is the best time of my Day.
              <div className="arrow"></div>
            </blockquote>
            <img src={testimonial_3} alt="sq-sample17" />
            <div className="author">
              <h5>Hardik Makhija<br /><span>Student</span></h5>
            </div>
          </figure>
          <figure className="snip1157">
            <blockquote>Music Class is the best time of my Day.
              <div className="arrow"></div>
            </blockquote>
            <img src={testimonial_4} alt="sq-sample17" />
            <div className="author">
              <h5>Devansh Dhyani<br /><span>Student</span></h5>
            </div>
          </figure>

        </div> */}
        <Component />
      </section>

      {/* testimonals ends */}

      {/* achievements */}
      <section id='achievements'>
        <div className="after"></div>
        <h1>achievements</h1>
        <div className='achievements'>

          <div className="achievements-content">
            <div className='achievements-content-img'>
              <img src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691563385/Dwarka%20School%20Of%20Music/Achievements/1_ifekzq.webp" alt="3" className='img-fluid' />
              <img src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691563385/Dwarka%20School%20Of%20Music/Achievements/2_fl37rt.webp" alt="1" className='img-fluid' />
              <img src="https://res.cloudinary.com/dcimsha8g/image/upload/v1691563385/Dwarka%20School%20Of%20Music/Achievements/3_jvbj7h.webp" alt="2" className='img-fluid' />
              <img src="https://res.cloudinary.com/dcimsha8g/image/upload/v1694680130/Dwarka%20School%20Of%20Music/Achievements/4_yzku59.png" alt="2" className='img-fluid' />
              <img src="https://res.cloudinary.com/dcimsha8g/image/upload/v1694680129/Dwarka%20School%20Of%20Music/Achievements/5_zc6i6p.png" alt="2" className='img-fluid' />

            </div>
            {/* <div className='achievements-content-text'>
              <h2>KARTAVYA & SWARA</h2>
              <h4><i>Passed their RSL Grade 1 Acoustic Guitar Examination With Distinctions</i></h4>
            </div> */}
          </div>
        </div>
      </section>
      {/* achievements ends */}

      {/* gallery starts */}
      <section id='gallery'>
        <h1>Gallery</h1>
        <div className='gallery'>
          <img src={gallery1} alt="" />
          <img src={gallery2} alt="" />
          <img src={gallery3} alt="" />
          <img src={gallery5} alt="" />

        </div >
      </section >
      {/* gallery ends */}

      {/* footer */}

      <footer class="footer-section">
        <div class="footer-content">
          <div class="row">
            <div class="col-xl-4 col-lg-3 mb-50">
              <div class="footer-widget">
                <div class="footer-widget-heading">
                  <h3>About us</h3>
                </div>
                <div class="footer-text">
                  <p> At Dwarka School Of Music- we believe in the power of music to inspire, educate, and transform lives. We provide exceptional music education and fostering a lifelong love and appreciation for music in our students.</p>
                </div>
                <div class="footer-social-icon">
                  {/* <div class="footer-widget-heading">
                    <h3>Follow us</h3>
                  </div> */}
                  <a target='_blank' href="https://www.google.com/search?q=dwarka+school+of+music&oq=dwa&aqs=chrome.1.69i60j69i59j69i57j46i131i433i512j0i433i512j0i512j46i131i433i512j0i131i433i650j0i512j46i131i433i650.1673j0j4&client=ms-unknown&sourceid=chrome-mobile&ie=UTF-8"><img src={google} alt="google" /></a>
                  <a target='_blank' href="https://www.instagram.com/dsom_07/"><img src={instagram} alt="instagram" /></a>
                  <a target='_blank' href="https://www.facebook.com/profile.php?id=100084562365978"><img src={facebook} alt="facebook" /></a>
                  <a target='_blank' href="https://www.youtube.com/@dwarkaschoolofmusic-ma"><img src={youtube} alt="facebook" /></a>
                </div>
              </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 mb-0">
              <div class="footer-widget">
                <div class="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                  <li><Link to={"/"}>Home</Link></li>
                  <li><Link to={"/about"}>About us</Link></li>
                  <li><a href="#classes">Classes</a></li>
                  <li><a href="#our_team">Our Team</a></li>
                  <li><Link to={"/gallery"}>Gallery</Link></li>
                  <li><Link to={"/privacy_policy"} >Privacy Policy</Link></li>
                </ul>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 mb-0">
              <div class="footer-widget">
                <div class="footer-widget-heading">
                  <h3>Contact Us</h3>
                </div>
                <div className="footer-widget-c">
                  <ul>
                    <li><svg id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m298.789 313.693c-12.738 8.492-27.534 12.981-42.789 12.981-15.254 0-30.05-4.489-42.788-12.981l-209.803-139.873c-1.164-.776-2.298-1.585-3.409-2.417v229.197c0 26.278 21.325 47.133 47.133 47.133h417.733c26.278 0 47.133-21.325 47.133-47.133v-229.198c-1.113.834-2.249 1.645-3.416 2.422z" /><path d="m20.05 148.858 209.803 139.874c7.942 5.295 17.044 7.942 26.146 7.942 9.103 0 18.206-2.648 26.148-7.942l209.803-139.874c12.555-8.365 20.05-22.365 20.05-37.475 0-25.981-21.137-47.117-47.117-47.117h-417.766c-25.98.001-47.117 21.137-47.117 47.142 0 15.085 7.496 29.085 20.05 37.45z" /></svg></li>
                    <li><svg id="Layer_3" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 3"><path d="m30.035 22.6c-.082-.065-6.035-4.356-7.669-4.048-.78.138-1.226.67-2.121 1.735-.144.172-.49.584-.759.877a12.517 12.517 0 0 1 -1.651-.672 13.7 13.7 0 0 1 -6.321-6.321 12.458 12.458 0 0 1 -.672-1.651c.294-.27.706-.616.882-.764 1.06-.89 1.593-1.336 1.731-2.118.283-1.62-4.005-7.614-4.05-7.668a2.289 2.289 0 0 0 -1.705-.97c-1.738 0-6.7 6.437-6.7 7.521 0 .063.091 6.467 7.988 14.5 8.025 7.888 14.428 7.979 14.491 7.979 1.085 0 7.521-4.962 7.521-6.7a2.283 2.283 0 0 0 -.965-1.7z" /></svg></li>
                    <li><svg id="Layer_3" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 3"><path d="m30.035 22.6c-.082-.065-6.035-4.356-7.669-4.048-.78.138-1.226.67-2.121 1.735-.144.172-.49.584-.759.877a12.517 12.517 0 0 1 -1.651-.672 13.7 13.7 0 0 1 -6.321-6.321 12.458 12.458 0 0 1 -.672-1.651c.294-.27.706-.616.882-.764 1.06-.89 1.593-1.336 1.731-2.118.283-1.62-4.005-7.614-4.05-7.668a2.289 2.289 0 0 0 -1.705-.97c-1.738 0-6.7 6.437-6.7 7.521 0 .063.091 6.467 7.988 14.5 8.025 7.888 14.428 7.979 14.491 7.979 1.085 0 7.521-4.962 7.521-6.7a2.283 2.283 0 0 0 -.965-1.7z" /></svg></li>
                    <li style={{ marginBottom: '0' }}><svg id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-105.6 0-191.5 85.9-191.5 191.5 0 34.4 16.2 77.8 49.4 132.7 27.6 45.6 61.8 90.9 91.9 130.8 13.9 18.4 27 35.8 37.1 50.2 3 4.3 7.9 6.8 13.1 6.8s10.1-2.5 13.1-6.8c10.1-14.4 23.2-31.8 37.1-50.2 30.1-39.9 64.2-85.2 91.9-130.8 33.2-54.9 49.4-98.3 49.4-132.7 0-105.6-85.9-191.5-191.5-191.5zm0 280c-48.8 0-88.5-39.7-88.5-88.5s39.7-88.5 88.5-88.5 88.5 39.7 88.5 88.5-39.7 88.5-88.5 88.5z" /></svg> </li>
                  </ul>
                  <ul>
                    <li><a href="mailto:contact.dsom@gmail.com" style={{ textTransform: 'lowercase' }}>contact.dsom@gmail.com                    </a></li>
                    <li><a href='tel:918287202085'>+91-8287202085</a> </li>
                    <li><a href='tel:911147091917'>+91-11-47091917</a></li>
                    <li><a>E-1083, 1st Floor, MPS Plaza, Ramphal Chowk Rd,Sector 7, Dwarka, New Delhi, Delhi 110075</a></li>
                  </ul>
                </div>

              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 mb-0">
              <div class="footer-widget">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1751.7434092385017!2d77.071909!3d28.585169!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b1636452edb%3A0xabd7d2e066dbca55!2sDwarka%20School%20of%20Music!5e0!3m2!1sen!2sin!4v1686645993272!5m2!1sen!2sin" allowfullscreen="" loading="lazy" title='footer_map' referrerpolicy="no-referrer-when-downgrade" ></iframe>
              </div>
            </div>

          </div>
        </div>

        <div class="copyright-area">
          <div class="container">
            <div class="row">
              <div class="col-xl-4 col-lg-6 text-center text-lg-left">
                <div class="copyright-text">
                  <p>Copyright &copy; {new Date().getFullYear()}, All Right Reserved <a href="https://ahsassuredservices.com/web_design_and_development">AHS</a></p>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div class="footer-menu">
                  <ul>
                    <li><Link to={"/privacy_policy"} >Privacy Policy</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* footer ends */}

    </div >
  )
}

export default Home
