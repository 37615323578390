import React from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import i1 from '../Images/Gallery/Infrastructure/1.webp'
import i2 from '../Images/Gallery/Infrastructure/2.webp'
import i3 from '../Images/Gallery/Infrastructure/3.webp'
import i5 from '../Images/Gallery/Infrastructure/12.webp'
import i6 from '../Images/Gallery/Infrastructure/6.webp'
import i7 from '../Images/Gallery/Infrastructure/7.webp'
import i8 from '../Images/Gallery/Infrastructure/8.webp'
import i9 from '../Images/Gallery/Infrastructure/9.webp'
import i10 from '../Images/Gallery/Infrastructure/10.webp'
import i11 from '../Images/Gallery/Infrastructure/11.webp'
import s1 from '../Images/Gallery/Students/1.jpg'
import s2 from '../Images/Gallery/Students/2.jpg'
import s3 from '../Images/Gallery/Students/3.jpg'
import s4 from '../Images/Gallery/Students/4.jpg'
import s5 from '../Images/Gallery/Students/5.jpg'
import s6 from '../Images/Gallery/Students/6.jpg'
import s7 from '../Images/Gallery/Students/7.jpg'
import s8 from '../Images/Gallery/Students/8.jpg'
import s9 from '../Images/Gallery/Students/9.jpg'
import s10 from '../Images/Gallery/Students/10.jpg'
import s11 from '../Images/Gallery/Students/11.jpg'
import s12 from '../Images/Gallery/Students/12.jpg'
import s13 from '../Images/Gallery/Students/13.jpg'
import s14 from '../Images/Gallery/Students/14.jpg'
import a1 from '../Images/Gallery/Achievements/1.jpg'
import a2 from '../Images/Gallery/Achievements/2.jpg'
import a3 from '../Images/Gallery/Achievements/3.webp'
import a4 from '../Images/Gallery/Achievements/4.webp'
import a5 from '../Images/Gallery/Achievements/5.webp'

import { useEffect } from "react"
import { useState } from 'react'

const Gallery = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [isShowninfrastructure, setIsShowninfrastructure] = useState(false);
    const [isShownstudents, setIsShownstudents] = useState(false);
    const [isShownachievements, setIsShownachievements] = useState(false);
    const [isShownall, setIsShownall] = useState(true);

    const infrastructure = event => {
        setIsShowninfrastructure(current => !current);
        setIsShownstudents(false);
        setIsShownachievements(false);
        setIsShownall(false);
        document.getElementById('infrastructure').style.backgroundColor = '#9c5c04';
        document.getElementById('achievements').style.backgroundColor = '#b6861d';
        document.getElementById('all').style.backgroundColor = '#b6861d';
        document.getElementById('students').style.backgroundColor = '#b6861d';
    };
    const students = event => {
        setIsShownstudents(current => !current);
        setIsShowninfrastructure(false);
        setIsShownachievements(false);
        setIsShownall(false);
        document.getElementById('students').style.backgroundColor = '#9c5c04';
        document.getElementById('achievements').style.backgroundColor = '#b6861d';
        document.getElementById('all').style.backgroundColor = '#b6861d';
        document.getElementById('infrastructure').style.backgroundColor = '#b6861d';
    };
    const achievements = event => {
        setIsShownachievements(current => !current);
        setIsShowninfrastructure(false);
        setIsShownstudents(false);
        setIsShownall(false);
        document.getElementById('achievements').style.backgroundColor = '#9c5c04';
        document.getElementById('all').style.backgroundColor = '#b6861d';
        document.getElementById('students').style.backgroundColor = '#b6861d';
        document.getElementById('infrastructure').style.backgroundColor = '#b6861d';
    };
    const all = event => {
        setIsShownall(current => !current);
        setIsShowninfrastructure(false);
        setIsShownstudents(false);
        setIsShownachievements(false);
        document.getElementById('all').style.backgroundColor = '#9c5c04';
        document.getElementById('achievements').style.backgroundColor = '#b6861d';
        document.getElementById('students').style.backgroundColor = '#b6861d';
        document.getElementById('infrastructure').style.backgroundColor = '#b6861d';
    };
    return (
        <div>
            <Header />
            <div id='pages-gallery'>
                <div className="pages-gallery">
                    <h1>Display  <span>Area</span></h1>
                    <div className="gallery-filter">
                        <button id='all' onClick={all}>All </button>
                        <button id='infrastructure' onClick={infrastructure}>infrastructure</button>
                        <button id='achievements' onClick={achievements}>achievements </button>
                        <button id='students' onClick={students}>students </button>
                    </div >
                    <div className="pages-gallery-images">
                        {isShownall && (

                            <div class="row">
                                <div class="column">
                                    <img src={i1} alt="" />
                                    <img src={i2} alt="" />
                                    <img src={i3} alt="" />
                                    <img src={i11} alt="" />
                                    <img src={i5} alt="" />
                                    <img src={i6} alt="" />
                                    <img src={s8} alt="" />
                                    <img src={s9} alt="" />
                                </div>
                                <div className='column'>
                                    <img src={s1} alt="" />
                                    <img src={s2} alt="" />
                                    <img src={s3} alt="" />
                                    <img src={i7} alt="" />
                                    <img src={i8} alt="" />

                                    <img src={s6} alt="" />
                                    <img src={s4} alt="" />
                                    <img src={i10} alt="" />
                                </div>
                                <div class="column">

                                    <img src={s5} alt="" />
                                    <img src={i9} alt="" />
                                    <img src={a1} alt="" />
                                    <img src={a2} alt="" />
                                    <img src={s3} alt="" />
                                    <img src={s7} alt="" />
                                </div>
                            </div>
                        )}
                        {isShowninfrastructure && (

                            <div class="row">
                                <div class="column">
                                    <img src={i1} alt="" />
                                    <img src={i2} alt="" />
                                    <img src={i3} alt="" />
                                </div>
                                <div class="column">
                                    <img src={i11} alt="" />
                                    <img src={i5} alt="" />
                                    <img src={i6} alt="" />
                                </div>
                                <div className='column'>
                                    <img src={i7} alt="" />
                                    <img src={i8} alt="" />
                                    <img src={i9} alt="" />
                                    <img src={i10} alt="" />
                                </div>
                            </div>
                        )}
                        {isShownstudents && (
                            <div class="row">
                                <div class="column">
                                    <img src={s10} alt="" />
                                    <img src={s11} alt="" />
                                    <img src={s1} alt="" />
                                    <img src={s2} alt="" />
                                    <img src={s3} alt="" />
                                </div>
                                <div class="column">
                                    <img src={s12} alt="" />
                                    <img src={s4} alt="" />
                                    <img src={s5} alt="" />
                                    <img src={s6} alt="" />
                                    <img src={s9} alt="" />
                                </div>
                                <div className='column'>
                                    <img src={s13} alt="" />
                                    <img src={s14} alt="" />
                                    <img src={s7} alt="" />
                                    <img src={s8} alt="" />
                                    
                                </div>
                            </div>
                        )}

                        {isShownachievements && (
                            <div class="row">
                                <div class="column">
                                    <img src={a1} alt="" />
                                    <img src={a2} alt="" />
                                </div>
                                <div class="column">
                                    <img src={a4} alt="" />
                                    <img src={a5} alt="" />
                                </div>
                                <div class="column">
                                    <img src={a3} alt="" />
                                </div>
                            </div>
                        )}


                    </div>
                </div>
            </div >
            <Footer />

        </div>
    )
}

export default Gallery
